<template>
  <div>
    <b-row>
      <b-col md="5">
        <b-list-group
          v-if="data"
          flush
        >
          <b-list-group-item>Nom: <b>{{ data.lastname }}</b></b-list-group-item>
          <b-list-group-item>Prénoms: <b>{{ data.firstname }}</b></b-list-group-item>
          <b-list-group-item>Adresse e-mail: <b>{{ data.email }}</b></b-list-group-item>
          <b-list-group-item>Age: <b>{{ data.age }}</b></b-list-group-item>
          <b-list-group-item>Nationalité: <b>{{ data.nationality }}</b></b-list-group-item>
          <b-list-group-item v-if="data.parent">Parent: <b>{{ data.parent.title }}</b></b-list-group-item>
          <b-list-group-item>Description: <b>{{ data.content }}</b></b-list-group-item>

          <b-list-group-item>Date de création:<br />
            <b>{{
              this.$coddyger.cdgDateFormat(data.created_at, true)
            }}</b></b-list-group-item>
          <b-list-group-item>Dernière mis à jour:
            <b>{{
              this.$coddyger.cdgDateFormat(data.updated_at, true)
            }}</b></b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="7">
        <b-row>
          <b-col lg="12">
            <b-list-group
              v-if="data.fiche_capillaire"
              flush
            >
              <b-list-group-item><b class="text-primary">Fiche capillaire</b></b-list-group-item>
              <b-list-group-item>Cassure: <b>{{ data.fiche_capillaire.cassure }}</b></b-list-group-item>
              <b-list-group-item>densite: <b>{{ data.fiche_capillaire.densite }}</b></b-list-group-item>
              <b-list-group-item>douceur: <b>{{ data.fiche_capillaire.douceur }}</b></b-list-group-item>
              <b-list-group-item>secheresse: <b>{{ data.fiche_capillaire.secheresse }}</b></b-list-group-item>
              <b-list-group-item>frisure: <b>{{ data.fiche_capillaire.frisure }}</b></b-list-group-item>
              <b-list-group-item>texture: <b>{{ data.fiche_capillaire.texture }}</b></b-list-group-item>
              <b-list-group-item>porosite: <b>{{ data.fiche_capillaire.porosite }}</b></b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import * as payloads from '../payloads.json'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      fcPayload: payloads.ficheCapillaire
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  methods: {
    buildFicheCapillaire(key, needle) {
      let data = this.fiche_capillaire.filter((item) => {
        
        return 'Aqwxszedcvfr'
      })
    }
  },
}
</script>
